/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.fc-h-event {
  background-color: rgb(147, 147, 147);
  border: none;
}

.fc-col-header-cell-cushion {
  color: grey;
}

.fc-daygrid-day-number {
  color: rgb(107, 107, 107);
}
.ant-modal {
  top: 2rem;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  color: #497174;
  background-color: #d6e4e5;
}
